@import url("../src/assets/fonts/Satoshi/Fonts/WEB/css/satoshi.css");
@import url("../src/assets/fonts/OpenSans/Fonts/opensans.css");
@import url("../src/assets/fonts/NunitoSans/nunitosans.css");
@import url("../src/assets/fonts/Roboto/roboto.css");



html {
  overflow-y: scroll;
}


body {
  color: #545454;
  font-size: 13px !important;
  line-height: 1.3;
  margin: 0;
}

.bg-color-dashboard{
  background-color: #EFF2FF;
}

.bg-body-sp {


  background-repeat: no-repeat;
  background-size: cover;
}

input,
select {
  font-size: 14px !important;
}

/* label {
  display: block;
  margin-top: 10px;
} */
.removeWhiteSpace {
  padding-top: 10px;
  padding-bottom: 10px;

}

.login-form,
.signup-form {
  max-width: 350px;
  margin: auto;
}


.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.bholu {
  position: absolute;
  top: 20%;
  left: 18%;
}

.sample {
  position: absolute;

  left: 7%;
}

.joshi {
  position: absolute;
  top: 40%;
  left: 20%;
}

.para {
  position: absolute;
  top: 264px;
  left: 10px;
}



.displayErrorMessage {
  display: flex;
  padding: 10px;
  justify-content: flex-end;
  position: absolute;
  z-index: 999;
  width: 100%;
}

.displayErrorMessage p {
  background: palevioletred;
  padding: 15px;
}

/* As per figma css */

.desktop-sidenave-typography {

  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0.15000000596046448px !important;
  text-align: left !important;
}

/* font classes  */

/* ENd As per figma css */

/* For Desktop View */
@media screen and (min-width: 1024px) {

  .login-float-right {
    float: right !important;
  }
}

/* For Tablet View */
/* @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
 
} */

/* For Mobile Portrait View */
/* @media screen and (max-device-width: 480px) and (orientation: portrait) {

} */

/* For Mobile Landscape View */
/* @media screen and (max-device-width: 640px) and (orientation: landscape) {

} */

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
  .login-float-none {
    float: none;
  }


}

/* For iPhone 4 Portrait or Landscape View */
/* @media screen and (min-device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {

} */

/* For iPhone 5 Portrait or Landscape View */
/* @media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {

} */

/* For iPhone 6 and 6 plus Portrait or Landscape View */
/* @media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {

} */

/* Font Size Class */

.font-size-14 {
  font-size: 14px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.maskedInput {
  -webkit-text-security: disc
}



/* Tooltip container */
.tooltip-custom {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip-custom .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 5px;
  position: absolute;
  z-index: 1;
  display: block;
  border-radius: 1px 8px 8px 16px;
  margin: 2px;
  font-size: 12px;
}

/* Show tooltip on hover */
.tooltip-custom:hover .tooltiptext {
  visibility: visible;
}



/* input tooltip  */

[data-tip] {
  position: relative;

}

[data-tip]:before {
  /* hides the tooltip when not hovered */
  display: none;

  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #1a1a1a;
  position: absolute;
  top: 30px;
  left: 35px;
  z-index: 8;
  font-size: 0;
  line-height: 0;
  width: 0;
  height: 0;
}

[data-tip]:after {
  display: none;
  content: attr(data-tip);
  position: absolute;
  top: 35px;
  left: 0px;
  padding: 5px 8px;
  background: #1a1a1a;
  color: #fff;
  z-index: 9;
  font-size: 0.75em;
  height: auto;
  line-height: 18px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  /* white-space:nowrap; */
  word-wrap: normal;
}

[data-tip]:hover:before,
[data-tip]:hover:after {
  display: block;
}


/* display none unwanted div */
.modal-backdrop {
  display: none !important;
}

/* global button color classes */

/* Primary */
.cob-btn-primary {
  background-color: inherit !important;
  color: #fff !important;
  border: 0px;
  font-weight: 600;
  padding: 5px;

}




/* secondary */

.cob-btn-secondary {
  background-color: #ff3e06 !important;
}

.cob-btn-secondary :hover {
  background-color: #f48566;

}

.align-items-center {
  -ms-flex-align: center !important;
}


.fs-12 {
  font-size: 12px;
}

.fs-10 {
  font-size: 10px;
}



/* datepicker css */

.react-datepicker-wrapper {
  display: block !important;
}

.react-date-picker__wrapper {
  border: none !important;
}

.react-date-picker {
  padding: 3px 2px 2px 4px !important;
  ;
}



/* sidenav bar */

.hide-menu-nav {
  display: none !important;
}

