@font-face {
    /*font-family: "Sofia-Pro-Regular";*/
    font-family: "Poppins-Regular";
    src: url("Poppins-Regular.ttf") format("truetype");
}


@font-face {
    /*font-family: "Sofia-Pro-Bold";*/
    font-family: "Poppins-Bold";
    src: url("Poppins-Bold.ttf") format("truetype");
}

@font-face {
    /*font-family: "Sofia-Pro-Semi-Bold";*/
    font-family: "Poppins-SemiBold";
    src: url("Poppins-SemiBold.ttf") format("truetype");
}


@font-face {
    /*font-family: "Sofia-Pro-Light";*/
    font-family: "Poppins-Light";
    src: url("Poppins-Light.ttf") format("truetype");
}