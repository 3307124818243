// @import url("../src/assets/fonts/Sofia/sofia.css");
@import url("../src/assets/fonts/Poppins/poppins.css");


// $primaryColor: #29205d;
$primaryColor: #154280;

// $buttonPrimary:
// $buttonOnHover:
// $buttonDisable:

// paragraphFontSize
// headingFontSize

// fontFamily




// outer dashboard
.headerBg {
    background-color: $primaryColor;
}




// --------------------------------------------------------------------------------- 

// inner dashboard 
.sidebarBg{
    background-color: $primaryColor  //;
}

.dashboardHeaderBg{
    background-color:$primaryColor  //#130532;
}




// --------------------------------------------------------------------------------------

.cob-primary-btn-bg{
    background-color: $primaryColor;
}


// global tag
p{
    font-family: "Poppins-Regular";
    font-weight: 500;
    font-style: normal;

}

h1,h2,h3,h4,h5{
    font-family: "Poppins-Regular";
    font-weight: 500;
    font-style: normal;
}

a, span, label {
    font-family: "Poppins-Regular";
    font-weight: 500;
    font-style: normal
}

